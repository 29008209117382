<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CONTACT_SECURITY_ROLE_TYPES } from './store'
import { ROUTES as CONTACT_SECURITY_ROLE_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../../components/thux-list/ThuxListMixin'

import ContactSecurityRoleTable from './ContactSecurityRoleTable'
import ContactSecurityRoleCommonFilters from './ContactSecurityRoleCommonFilters'
import ContactSecurityRoleEdit from './ContactSecurityRoleEdit.vue'

export default {
  name: 'ContactSecurityRoleList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': ContactSecurityRoleTable,
    'common-filters': ContactSecurityRoleCommonFilters,
    'component-detail': ContactSecurityRoleEdit,
    'component-edit': ContactSecurityRoleEdit
  },
  data () {
    return {
      CONTACT_SECURITY_ROLE_ROUTES,
      title: this.$t('Contact security roles'),
      rolePerm: ['organization_contactsecurityrole_list'],
      actionEnablePermission: ['organization_contactsecurityrole_enable'],
      actionDisablePermission: ['organization_contactsecurityrole_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...CONTACT_SECURITY_ROLE_TYPES.GENERIC.organization.contactsecurityrole.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.CONTACT_SECURITY_ROLE_ROUTES.CONTACT_SECURITY_ROLE_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_contactsecurityrole_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: CONTACT_SECURITY_ROLE_TYPES.GENERIC.organization.contactsecurityrole.LIST.MUTATIONS.setSelectedList,
      setSelectAll: CONTACT_SECURITY_ROLE_TYPES.GENERIC.organization.contactsecurityrole.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...CONTACT_SECURITY_ROLE_TYPES.GENERIC.organization.contactsecurityrole.LIST.ACTIONS
    })
  }
}
</script>
